<template>
  <form @submit.prevent="save()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" class="form-control" name="name" v-model.trim="form.name" required />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="trainer">Trainer</label>
          <input
            type="text"
            class="form-control"
            name="trainer"
            v-model.trim="form.trainer"
            required
          />
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label for="weekday">Wochentag</label>
          <select name="weekday" v-model.number="form.weekday" class="form-control" required>
            <option value="-1" disabled>Wochentag auswählen...</option>
            <option
              :value="weekday"
              v-for="weekday in weekdays"
              :key="weekday"
            >{{weekday | weekday}}</option>
          </select>
        </div>
      </div>
      <div class="col-6 col-xl-3">
        <div class="form-group">
          <label for="starts">Beginnt</label>
          <input type="time" name="starts" v-model="form.starts" class="form-control" required />
        </div>
      </div>
      <div class="col-6 col-xl-3">
        <div class="form-group">
          <label for="ends">Endet</label>
          <input type="time" name="ends" v-model="form.ends" class="form-control" required />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="studio">Studio</label>
          <select name="studio" v-model="form.studio" class="form-control" required>
            <option value="-1" disabled>Studio auswählen...</option>
            <option :value="studio.id" v-for="studio in studios" :key="studio.id">{{studio.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-12" v-if="form.studio">
        <div class="form-group">
          <label for="course-room">Kursraum</label>
          <select name="course-room" v-model="form.courseRoom" class="form-control">
            <option value="-1" disabled>Kursraum auswählen...</option>
            <option
              :value="courseRoom.id"
              v-for="courseRoom in courseRooms"
              :key="courseRoom.id"
            >{{courseRoom.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-dark" :disabled="submitting">Speichern</button>
    <div class="alert alert-danger mt-3" v-if="errMsg">{{errMsg}}</div>
  </form>
</template>

<script>
import { getDay, format, addMinutes } from 'date-fns';

import { firestore } from '../firebase';

export default {
  data() {
    return {
      course: null,
      form: {
        name: '',
        trainer: '',
        weekday: getDay(Date.now()),
        starts: format(Date.now(), 'HH:mm'),
        ends: format(addMinutes(Date.now(), 30), 'HH:mm'),
        studio: '',
        courseRoom: ''
      },
      submitting: false,
      studios: [],
      courseRooms: [],
      weekdays: [0, 1, 2, 3, 4, 5, 6],
      errMsg: ''
    };
  },
  async created() {
    const id = this.$route.params.id;
    const studioId = this.$route.params.studioId;

    this.form = { ...this.form, studio: studioId };

    const studiosSnapshot = await firestore
      .collection('studios')
      .orderBy('nr')
      .get();

    const courseRoomsSnapshot = await firestore
      .collection('studios')
      .doc(studioId)
      .collection('courseRooms')
      .get();

    this.studios = studiosSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    this.courseRooms = courseRoomsSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    if (id) {
      const courseSnapshot = await firestore
        .collection('courses')
        .doc(id)
        .get();

      this.course = { id: courseSnapshot.id, ...courseSnapshot.data() };
      this.form = { ...this.course };

      delete this.form.id;
    }
  },
  methods: {
    async save() {
      this.submitting = true;
      this.errMsg = '';

      try {
        if (this.course) {
          await firestore
            .collection('courses')
            .doc(this.course.id)
            .update({
              ...this.form
            });
        } else {
          await firestore.collection('courses').add({
            ...this.form
          });
        }

        this.$router.push({
          name: 'courses'
        });
      } catch (err) {
        this.errMsg = err.message;

        throw err;
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
